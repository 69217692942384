@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./styles/animation";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto";
}

@layer base {
  html {
    color: #00304a;
    @apply bg-white-400;
  }
}

app-main-content-layout {
  .content-title {
    @apply border-b;
    border-color: #e2e2e2;
    @apply pb-6;
    @apply font-bold;
  }
}

.blue-snackbar {
  background: #5a73ce;
  color: white;
}

.green-snackbar {
  background: #00d46a;
  color: white;
}

.red-snackbar {
  background: #d9534f;
}
